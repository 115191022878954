.span-call{
    font-size: 12px;
    text-decoration: unset;
    margin-left: 5px;

}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSizeSmall.MuiButton-sizeSmall {
   
    /* padding: 6px; */
    /* margin-left: 10px; */
    margin-right: 8px;
}
.headercall-mygroups .group-member-inner-details{
    border-radius: 9px;
    /* box-shadow: 0px 4px 35px 12px #0000000d; */
    align-items: center;
    padding: 5px 0;
    justify-content: center;
    display: flex;
}
.headercall-mygroups .count{
    /* margin-left: 30px; */
}
.call-button{
    padding-left: 26px;

}
.group-member-img {
    /* width: 25%; */
    text-align: center;
    justify-content: center;
    display: flex;
}
.member{
    display: grid;
    align-items: center;
    text-align:center; 
    justify-content: center;
}
.two-buttons{
    margin-top: 10px;
}
.member-form{
   
        border-radius: 8px;
        margin-top: 10px;
    
}
.group-created{
    display: grid;
    /* float: left; */
    margin-right: 220px;
    margin-top: 18px;

}
.cell {
    margin-top: 15px;
    border-bottom: 0.75px solid #D7D7D7;
  }
  .group-icon-img{
    width:"25%"
  }
  .call-group-info{
      display: flex;
  }
  .delete-btn{
      color: red;
  }
  .MuiButton-text {
    padding: 6px 8px;
    /* color: aqua; */
    color: red;
   text-transform: lowercase;
}
.sections-created{
    margin-top: 20px;
}

.created-grid {
    text-align: left;
    padding-left: 15px;
}
.group-language{
    text-align: right;
    padding-right: 15px;
}
.call-btn{
    margin-right: 16px;
}
.member{
    text-align: left;
    padding-left: 15px;
    font-size: 14px;
}
.viewall {
    font-size: 14px;
    margin-left: 258px;
}
.date{
    font-size: 14px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.delete-btn {
    color: red;
}

.member-delete{
    width: 30px;
    margin-left: 200px;
    margin-top: 20px;
}
    
img.Icon_member {
    width: 30px;
   
}
.Icon_member_added{
    color:blue
}
.group-call-bottom-content{
	margin-bottom: 15px;
    margin-top: 10px;
}
span.MuiButton-label {
    padding: 1px;
}
.Icon_member-btn{
    width: 20px;
    margin-right: 7px;
}
.vertical_scrollbar{
	overflow-y: scroll;
	scrollbar-color: #699bf7 #fff;
	scrollbar-width: thin
}

.box-shadow.text-center.member-form.vertical_scrollbar {
    max-height: calc((50vh - 2px));
}
.box-shadow.text-center.popup.vertical_scrollbar {
    max-height: calc((80vh - 2px));
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.back-button {
    margin-right: 85%;
}

.box-shadow.text-center.group-list.vertical_scrollbar {
    max-height: calc((50vh - 2px));
}
.MuiGrid-root.login-container1 {
    
    position: relative !important;
    min-height: 50vh;
    justify-content: flex-start;
    background-color: white;
}
button.close1 {
    margin-left: 86%;
    margin-top: 1.5%;
}
/* interpreterr css starts here */
.Interpreter-login-header{
    /* display: grid; */
    justify-content: center;
     display: flex;
}
.edit {
    /* float: left; */
    float: right;
    margin-left: 12px;
    padding-left: -12px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    padding-right: 10px;
}
img.edit-img {
    float: right;
    width: 30px;
}

.MuiGrid-root.login-container.interpretlogin.MuiGrid-container.MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center .MuiGrid-root.header-login {
    margin: 0px;
}

.MuiGrid-root.login-container.interpretlogin.MuiGrid-container.MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center {
    background: white;
}
.login-text-interpreter{
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 17.57px;
    text-align: center;
    color: #818181;

}
.group-person-missed-call{
width: 60px;
height: 60px;
border-radius: 30px;
/* border:2px solid black */
}
.missedcall-names {
    font-weight: bold;
    color: #515151;
}
.interpreter-profile-edit-form-main{
    padding-left: 18px;
}
.call-person-form-main-interpreter input[type=checkbox]{
	position: relative;
    z-index: 9;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    opacity: 0;
}

.call-person-form-main-interpreter .form-div-interpreter {
    border: 0.75px solid #5490D6;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 2px 10px 5px;
}
.interpreter-profile-form-main{
	padding: 20px 20px 50px;
}
.Interpreter-details{
font-weight: 600;
font-size: 18px;
color:#717171;
}
.single_profinitial-interpreter {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #B4C6E2;
    font-size: 20px;
    color: #597FBB;
    text-align: center;
    line-height: 65px;
    margin: 0;
    margin-left: 12px;
}
.call-person-status-interpreter {
    font-weight: 700;
    font-size: 15px;
   
    color: #515151;
}
.missedcall-names-1 {
    font-weight: bold;
    color: #515151;
    margin-left: 30px;
}
.interpreter-time {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #ABABAB;
}
.interpreter-time-1{
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #ABABAB;
    text-align: left;
}
.interpreter-time-1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #ABABAB;
    text-align: right;
    /* margin-left: -12px; */
    margin-right: 19px;
}
h3.text {
    text-align: center;
    color: #515151;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
}
.edit-title-profile{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgba(81, 81, 81, 0.6);
}
.box-shadow.radius-8px.vertical_scrollbar {
    max-height: calc((61vh - 2px));
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.break-reason {
    padding: 5px 15px;
    border: 1px solid #2760A3; 
    border-radius: 22px;

}
.interpreter-feedback-inner {
    border-radius: 8px;
    border: 0.005px solid #D0E1F4;
}
.feedbackinfo-inner {
    padding: 10px 30px 30px;
}
.feedback-inner-title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 630;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
}
.MuiFormControl-root.MuiTextField-root.textbox-style {
    border: 1px solid #D0E1F4;
    border-radius: 8px;
}
.interpreter-feedback-inner {
    height: 100px;
}
.feeback-title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 640;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #818181;
}
.MuiGrid-root.feeback-btn.MuiGrid-grid-md-4 {
    justify-items: center;
    display: block;
    align-items: center;
    margin: 0px auto;
}
.btn_nt_blur{
    width:"302px";
    border-radius:"8px";
    background-color: "#2760A3"
  }
  .btn_blur{
   
   border-radius:"8px";
   background-color: #D0E1F4 !important;
   
    /* background: #2760A3; */
   
  }