.form-div{
    border: 0.75px solid #2760a3;
    border-radius: 8px;
    margin: 12px;
    padding: 2px 10px 5px;
}
.call-person-form-main .form-div input:not(input[type=checkbox]){
    border: none;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
}
.form-input{
    border: none;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
}
option:first-letter {
  background-color: yellow;
  color: red;
}
select#language_id {
    color: black;
    font-size: small;
}

.MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup {
    background-color:white !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.7); */
    border-bottom: none !important;
}
.MuiInput-underline:hover(.Mui-disabled):before {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.7); */
    border-bottom: none !important;
}

