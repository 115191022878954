@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body{
	margin: 0;
	font-family: Mulish;
	min-height: 100vh;
}
#root{
	background: #00000014;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
}
#root>div:first-child:not(.header){ margin-left: auto;margin-right: auto; }
#root .header{width: 100%;}
.header-main-logo{
	width: 295px;
	height: 80px;
	background: #2760a3;
	display: flex;
	/* justify-content: center; */
	align-items: center;
}
.header-main-logo img{width:  65%;}
.header-main-block{
	display: flex;
}
.header-main-nav{
	display: flex;
	align-items: center;
	width: calc(100% - 295px);
	box-shadow: 0px 4px 45px 12px #00000014;
	background: #fff;
}
.header-main-nav-inner{
	padding: 0 50px;
	width: 75%;
}
.header-main-nav-inner > div{
	display: inline-block;
	padding: 0 20px;
}
.header-text p{
	margin: 0;
	text-align: center;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #BAC0CE;
}
.header-nav > a.active .header-text p{
	color: #2760A3;
}
.header-nav > a{
	text-decoration: none;
}
.header-icon{
	text-align: center;
}
.header-user-profile{
	width: 25%;
	position: relative;
}
.header-user-profile:before{
	content: "";
	display: block;
	height: 70px;
	width: 1px;
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	left: -20px;
	top: -6px;
}
.header-user-profile > div{
	display: inline-block;
}
.user-profile-details{
	vertical-align: text-bottom;
	margin-left: 15px;
}
.user-name{
	font-size: 16px;
    font-style: normal;
    font-weight: 700;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #515151;
    display: inherit;
}
.user-profile-details img{
	margin-left: 15px;
}
.sidebar-main{
	width: 295px;
    display: inline-block;
    background: #fff;
    box-shadow: 0px 4px 35px 12px rgb(0 0 0 / 10%);
    min-height: calc((100vh - 80px));
    height: auto;
}
.sidebar-block{
	text-align: center;
    padding: 22px 11px 20px;
    position: relative;
    display: flex;
}
.sidebar-block:after{
	content: "";
	width: calc(100% - 46px);
	height: 1px;
	background: rgba(64, 149, 249, 0.4);
	position: absolute;
	bottom: 0px;
	display: block;
	left: 23px;
}
.sidebar-block .side-txt-bar{
	width: calc(100% - 70px);
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.sidebar-inner > a.active .sidebar-block{
	background: #D0E1F4;
}
.sidebar-inner > a{
	text-decoration: none;
}
.sidebar-block .side-img{
	background: #FF9D00;
    border-radius: 50%;
    width: 47px;
    height: 47px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
.sidebar-block:nth-child(2) .side-img{
	padding: 10px;
}
.sidebar-block .side-img img{
	width: 25px;
}
.sidebar-block .side-title{
	color: #2760A3;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin: 0 0 3px;
    text-align: left;
}
.sidebar-block .side-txt{
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 12px;
	font-style: normal;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	color: #2760A3;
	margin: 3px 0 0;
}
.page-main{
	display: inline-block;
	width: calc(100% - 295px);
	vertical-align: top;
	background: #f9f8f9;
	padding-bottom: 30px;
}
.page-main-inner{
	padding: 40px;
}
.box-shadow{
 background: #FFFFFF;
box-shadow: 0px 4px 35px 12px rgba(0, 0, 0, 0.05);
}
.text-center{
 text-align: center;
}
.home-user-profile-top-txt p {
    line-height: 20px;
    justify-content: center;
    margin-top: 5px;
}
.intsa_icon{position: absolute; right: 15px; bottom:  0;  background: #fff; border-radius: 50%;    z-index: 1;}
.home-user-profile-img{position: relative; width: fit-content; width: -moz-fit-content; width: -webkit-fit-content; margin-left: auto; margin-right: auto;}
.profile_details span{ font-family: Mulish;font-style: normal;font-weight: 1000;font-size: 16px;line-height: 21px;display: flex;align-items: center;text-align:center; justify-content: center;color: #515151; }
span.prof-user-name {font-weight: 600;}
.profile_details {padding: 15px 0px 0px 0px;}
.home-user-profile-inner {padding: 20px 0px;}
.blue-title{
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;
	color: #2760A3;
	margin-bottom: 25px;
}
.normal-gery-text{
	font-family: Mulish;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	display: flex;
	align-items: center;
	color: #515151;
}
.contact-info-detail>div {display: flex;     margin-bottom: 20px;}
.contact-info-inner {  padding: 20px 40px;}
.contact-info-detail>div svg { margin-right: 10px;}
.radius-8px{
	border-radius: 8px;
}
.single_contact >img {
    width: 60px;
    border-radius: 50%;
}
.single_contact {  display: flex;align-items: center; width: 100%;     padding: 10px 0px;}
.single_contact  .call_count {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #9A9A9A;
    width: 70px;
    justify-content: flex-end;
}
.single_contact .names {
    margin-left: 25px;
    width: calc((100% - 150px));
    font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	color: #515151;
}
.single_contact  .call_count img{margin-left: 10px;}
.my-contacts-inner {  padding: 10px;}
.single_contact:not(:last-child) { border-bottom: 0.75px solid #E0EDFA;}
.single_contact:first-child{padding-top: 0px;}

.header-icon svg {width: 20px;     height: 30px;}
.header-nav.home .header-icon svg {  width: 27px;}
.header-nav > a.active path, .call-person-tab-header .Mui-selected svg path { fill: #2760A3;}
.call-person-tab-header svg path{fill: rgb(146, 184, 229);}
.header-nav.home > a:not(.active) path {  fill: #BAC0CE;}


.single_callhistory .profile_intial {  display: inline-block; width: 50px; height: 50px; line-height: 50px; font-size: 18px;  border-radius: 55%;}
.single_callhistory .profile_intial:first-child {  z-index: 1; position: relative; }
.single_callhistory .profile_intial:nth-child(2) {margin-left: -25px;position: relative;}
.single_callhistory .profile_intial:nth-child(3) {margin-left: -25px; }
.single_callhistory {display: flex; align-items: center;     margin-bottom: 25px; border-bottom: 0.2px solid #E0EDFA;}
.single_callhistory .call_names_inter {width: calc((100% - 235px));margin-left: 20px;     display: flex; flex-wrap: wrap;}
.single_callhistory .call_names_inter span { width: 100%; }
span.call_date {width: 110px;text-align: right;}
.call-history-inner {padding: 10px 17px;}
.single_callhistory .callhistory_names{font-style: normal;font-weight: bold; font-size: 16px; line-height: 20px;color: #515151;}
.single_callhistory .callhistory_inter {font-style: normal;font-weight: bold;font-size: 14px;line-height: 18px;color: #515151;opacity: 0.5; }
.single_callhistory .call_date { font-weight: bold; font-size: 12px; line-height: 15px; text-align: right; color: #9A9A9A;}


.footer {
    display: inline-block;
    width: calc(100% - 295px);
    vertical-align: top;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fff;
}
.footer .copyright {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #9A9A9A;
    padding: 10px;
}
.user-profile-details-menu {display: none;}
.user-profile-details-menu.show {display: block;}
.user-profile-details-menu.show {
    position: absolute;
    right: 0;
    top: 60px;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    right: 20px;
    width: 100px;
}
.profile-menu-link { font-weight: 500; font-size: 18px; line-height: 26px; display: flex; align-items: center; color: #515151;}
.user-profile-details-menu a { text-decoration: none; }
.header-main-logo a{text-align: center;}
@media (min-width: 993px){ 
	.header-user-profile{  float: right; text-align: right;  padding-right: 20px; } 
}

@media (max-width:  991px){
 .header-main-logo{  padding: 5px; width: 55px;height: fit-content; height: -moz-fit-content; height: -webkit-fit-content;}
 .header-main-logo img{width: 100%;}
 .sidebar-main .side-txt-bar {display: none;}
 .sidebar-main{width: 65px; min-height: calc((100vh - 65px));}
 .header-main-nav .header-text {display: none;}
 .header-main-nav{width: calc(100% - 65px);}
 .header-main-nav-inner{padding: 0;}
 .header-nav.home .header-icon svg {width: 25px;}
 .header-icon svg { width: 20px; }
 .header-main-nav-inner > div{padding: 0px 0px;   width: calc((100% - 3% )/5);}
 .header-user-profile{ width: auto; }
 .header-user-profile .user-profile-details {display: none;}
 .header-main-nav-inner{width: calc((100% - 60px));}
 .header-user-profile:before{height: 67px; left: -10px; top: -6px;}
 .page-main{ width: calc(100% - 65px);  }
 .page-main-inner { padding: 5px;}
 .MuiGrid-root.main-grid {width: 100%;margin: 0;}
 .main-grid .MuiGrid-root.MuiGrid-item {width: 100%; }
 .sidebar-block:after{ width: 0; }
 
}
.selecteddropdown *{
	border:none !important;
    margin-top: -8px;
}
 .footer{width: calc(100% - 65px);}
 #root > .header, #root > .sidebar-main, #root > .page-main, #root > .footer{position: static !important;}
 #root > .page-main{padding-top: 0 !important;}
 .sidebar-block .side-img{height: 45px; width: 45px;}
 .sidebar-block .side-img img{width: 25px;}



@media (max-width:  767px){
.single_callhistory .profile_intial{ width: 40px; height: 40px; line-height: 40px; font-size: 16px;}
.single_callhistory .call_date{width: 110px;}
.single_callhistory .call_names_inter{width: calc((100% - 195px)); margin-left: 10px;}
.user-profile-details-menu.show{right: 15px;}
.single_contact .names{font-size: 18px; margin-left: 15px;}
.call-person-tab-header > div{overflow: scroll !important;}
}

@media (max-width:350px){
	.header-user-profile{width: 40px;}
	.header-user-profile img{width: 100%;}
	.header-main-logo{width: 40px;}
	.sidebar-main{width: 50px; min-height: calc((100vh - 50px));}
	.page-main,.header-main-nav{width: calc(100% - 50px);}
	.header-main-nav-inner{width: calc((100% - 48px));}
	.header-user-profile:before{height: 54px;}
	.sidebar-block .side-img{width: 35px; height: 35px;}
}
@media screen and (min-width: 1024px) and (max-width: 1024px){
	.header-main-nav-inner{
		padding: 0 5px;
		width: 66%;
	}
	.header-main-nav-inner > div{
		padding: 0 15px;
	}
	.header-user-profile{
		width: 34%;
	}
	.header-user-profile:before{
		left: 0;
	}
}
#root > .header{
	position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
#root > .sidebar-main{
	position: fixed;
	left: 0;
	top: 80px;
	z-index: 10;
}
#root > .page-main{
	position: relative;
	padding-top: 80px;
	left: 295px;
}
#root > .footer{
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 10;
}
.call-person-tab-header .MuiTab-textColorInherit{
	min-width: unset;
	padding: 9px 20px 6px;
}
.call-person-tab-header .MuiTabs-indicator{
	background-color: #2760A3;
}
.call-person-tab-content{
	padding: 20px 20px 42px;
	box-shadow: 0px 4px 35px 12px #0000000d;
}
.call-person-tab-header h3{
	font-size: 16px;
    font-weight: 800;
    line-height: 19px;
    color: #2760A3;
    text-align: left;
    margin-top: 0;
    margin-bottom: 15px;
}
.call-person-search-form{
	text-align: left;
}
.call-person-search-form .search-contacts{
	width: calc(100% - 50px);
	padding-left: 50px;
	height: 40px;
	border: 0.5px solid #BAC0CE;
    border-radius: 8px;
    font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	color: #9A9A9A;
    font-family: Mulish;
}
.call-person-search-form .search-contacts::-webkit-input-placeholder{
	color: #9A9A9A;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
}
.call-person-search-form .search-contacts::placeholder{
	color: #9A9A9A;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
}
.call-person-search-form form{
	position: relative;
}
.call-person-search-form form img{
	position: absolute;
	padding: 10px;
	z-index: 1;
	width: 20px;
	top: 2px;
}
.call-person-search-form form #search-contacts-btn{
	position: absolute;
    top: 0;
    opacity: 0;
    padding: 0;
    height: 47px;
    z-index: 999;
}
.call-person-search-form form #search-contacts-btn:hover, .call-person-form-main .form-div #video-call:hover, .call-person-form-main input[type=checkbox]:hover{
	cursor: pointer;
}
.call-person-profile-inner > .call-person-tab-header{
	background-color: #ECF0FF;
	border-radius: 8px 8px 0 0;
	border: 1px solid rgba(0, 0, 0, 0.15);
}
.call-person-bottom-title{
	font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #515151;
}
.call-person-details{
	display: flex;
    border-radius: 9px;
    box-shadow: 0px 4px 35px 12px #0000000d;
    align-items: center;
    padding: 5px 0;
}
.call-person-img{
	width: 30%;
}
.call-person-inner-details{
	width: 50%;
}
.call-person-video-call{
	width: 20%;
}
.call-person-img img{
	width: 65px;
}
.call-person-name p{
	font-size: 16px;
    font-weight: 800;
    line-height: 21px;
    text-align: left;
    margin: 0;
    color: #2760A3;
    margin-bottom: 3px;
}
.call-person-status p{
	font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    color: #D7D7D7;
}
.call-person-bottom-content{
	margin-bottom: 15px;
}
.call-person-video-call img{
	width: 40px;
}
.call-person-tab-header span.MuiTab-wrapper{
	font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    color: #92B8E5;
}
.call-person-tab-header .Mui-selected span.MuiTab-wrapper{
	color: #2760A3;
	font-weight: 900;
}
.call-person-form-title{
	padding: 11px 20px;
    background: #ECF0FF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px 8px 0 0;
}
.call-person-form-title h3{
	font-size: 16px;
    font-weight: 800;
    line-height: 21px;
    color: #2760A3;
}
.call-person-form-main{
	padding: 57px 20px 50px;
}
.call-person-form-main .form-div{
	border: 0.75px solid #2760A3;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 2px 10px 5px;
}
.call-person-form-main .form-div label{
	display: block;
	font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #5490D6;
}
.call-person-form-main .form-div input:not(input[type=checkbox]){
	width: 100%;
	border: none;
	font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}
.call-person-form-main .form-div input::-webkit-input-placeholder{
	color: #D7D7D7;
}
.call-person-form-main .form-div input::placeholder{
	color: #D7D7D7;
}
.call-person-form-main .form-div select{
	width: 100%;
    border: none;
    font-family: Mulish;
    font-size: 17px;
    font-weight: 700;
    color: #D7D7D7;
}
.call-person-form-main .cb-div{
	font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    color: #9A9A9A;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
}
.call-person-form-main .form-div .img-label{
	display: flex;
    padding: 7px 0;
}
.call-person-form-main .form-div .img-label img{
	width: 30px;
}
.call-person-form-main .form-div .img-label input{
	width: calc(100% - 40px);
    padding-left: 15px;
}
.call-person-form-main .form-div input:focus, .call-person-form-main .form-div select:focus{
	outline: none;
}
.call-person-form-main .form-div #video-call{
	background: transparent;
	height: 54px;
	z-index: 99;
	opacity: 0;
}
.call-person-form-main .submit-call{
	position: relative;
	padding: 0;
}
.call-person-form-main .submit-call img{
	position: absolute;
	left: 44%;
	top: 15px;
}
.call-person-form-main input[type=checkbox]{
	position: relative;
    z-index: 9;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    opacity: 0;
}
.checkmark{
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: #eee;
	border: 1px solid #2760A3;
	border-radius: 2px;
}
.call-person-form-main .cb-div input:hover ~ .checkmark{
	background-color: #ccc;
}
.call-person-form-main .cb-div input:checked ~ .checkmark {
  background-color: #2196F3;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.call-person-form-main .cb-div input:checked ~ .checkmark:after {
  display: block;
}
.call-person-form-main .cb-div .checkmark:after {
  left: 6px;
  top: 1px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.add-padding-bott{
	padding-bottom: 43px;
}

@media (max-width: 767px){ 
	.call-person-img { width: 70px;}
	.call-person-img img{ width: 50px; }
	.call-person-video-call img{ width: 30px; }
	.call-person-video-call{ width: 40px; }
	.call-person-inner-details{ width: calc((100% - 115px)); }
 }

@media (max-width: 360px){ 
	.call-person-img { width: 50px; } 
	.call-person-img img{ width: 40px; }
	.call-person-name p{ font-size: 14px; line-height: 16px; }
	.call-person-video-call img{ width: 25px; }
	.call-person-video-call{ width: 35px; }
	.call-person-inner-details{     width: calc((100% - 85px)); }
}

button.primary{     font-family: Mulish; background: #2760A3;border: 0.75px solid #2760A3; border-radius: 8px; color: #fff;    font-weight: 500;    font-size: 16px;    line-height: 26px;    width: 100%;    text-transform: capitalize;    padding: 10px; }
button.primary svg { margin-right: 10px; }
button.primary:hover{ background: #2760A3;border: 0.75px solid #2760A3; }
@media (max-width: 767px){
	button.primary{ font-size: 16px; padding: 8px;}
	button.primary svg{ width: 30px; }
	.call-person-form-main .submit-call img{width: 35px;    top: 10px;}
	.call-person-form-main .form-div #video-call{height: 45px;}
	#root > .footer{ width: 100%; }
}

.page-main-inner.conference .MuiGrid-grid-md-8 .call-person-tab-header button{ min-width: 25%; }
.page-main-inner.conference .call-person-bottom-title p { display: flex; align-items: center; }
.call-person-bottom-title{ font-family: Mulish; }
.page-main-inner.conference .call-person-bottom-title img { margin-right: 10px; }
.text-align-left{text-align: left;}
.page-main-inner.conference .MuiGrid-grid-md-8 .form-div.cb-div.text-align-left {
    font-family: Mulish;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #9A9A9A;
    position: relative;
    display: flex;
    align-items: center;
}
.page-main-inner.conference .form-div.cb-div input[type=checkbox] {
    position: relative;
    z-index: 9;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    opacity: 0;
}
.page-main-inner.conference .form-div.cb-div .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #2760A3;
    border-radius: 2px;
}
.page-main-inner.conference .form-div.cb-div input:checked ~ .checkmark {
    background-color: #2196F3;
}
.page-main-inner.conference .form-div.cb-div .checkmark:after {
    left: 6px;
    top: 1px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.page-main-inner.conference .form-div.cb-div input:checked ~ .checkmark:after {
    display: block;
}
.page-main-inner.conference .MuiGrid-grid-md-8 .form-div.cb-div.inte{ margin-top: 30px; }
.conference .form-div.language-detected {
    background: #F3F7FB;
    border: 0.5px solid #92B8E5;
    border-radius: 8px;
    text-align: left;
    padding: 15px;
}
.conference .detect{ display: block;     text-align: left; font-family: Mulish;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #515151;
	margin-bottom: 10px;
}
.conference .change{ 
	float: right; 
	font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #9A9A9A;
}
.conference .language {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    color: #2760A3;
}
.conference .language svg{ margin-right: 5px; }

@media (min-width: 768px){
	.conference .MuiGrid-grid-md-8 .call-person-img {
	    width: 115px;
	}
	.conference .MuiGrid-grid-md-8 .call-person-inner-details {
	    width: calc((100% - 190px));
	}
	.conference .MuiGrid-grid-md-8 .call-person-video-call {
	    width: 50px;
	}
}


.processing-call-tab-inner{     background: #F3F7FB;   border: 0.5px solid #5490D6;  border-radius: 8px; }
.processing-call-tab-content { padding: 10px 70px; }
.processing-call-tab-content .inte-profile img {
    border-radius: 50%;
    width: 70px;
}
.processing-call-tab-content .calling-names-dash {
    display: flex;
    align-items: center;
    background: #5490D6;
    border-radius: 8px 8px 0px 0px;
    padding: 10px;
}
.inte-profile-details >span{width: 100%;     margin-bottom: 3px;}
.inte-profile-details span {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}
.inte-profile-details {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
}
.inte-profile-details>div{ display: flex; align-items: center;    margin-top: 3px; }
.inte-profile-details span.connecting_person,.inte-profile-details span.connecting_person_names {
    display: inherit;
}
.inte-profile-details .connecting_person { margin-right: 15px;}
.connecting_person_names img {
    margin-right: 5px;
}

.processing-call-tab-content .two_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}
.processing-call-tab-content .two_buttons button.primary {
    width: auto;
    background: transparent !important;
    color: #2760A3;
    font-weight: 600;
    padding: 10px 50px;
}
.processing-call-tab-content .two_buttons button.secondary {
    color: #F54A4A;
    background: transparent !important;
    border: 1px solid #F54A4A;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    border-radius: 8px;
    font-family: Mulish;
    text-transform: capitalize;
}
.processing-call-tab-content .two_buttons button.secondary svg {
    width: 14px;
    margin-right: 5px;
}
.processing-call-tab-content .two_buttons button.primary svg {
    width: 20px;
}
.processing-call-tab-content .two_buttons button {
    margin: 0px 10px;
}
.connect-interpreter {
    text-align: center;
    padding: 30px;
}
.connect-interpreter .Connecting_interpreter {   margin: 50px 0px 20px 0px; }
.connect-interpreter >p{
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #515151;
}
.connect-interpreter >img:first-child {  width: 32px;   margin-bottom: 20px;  margin-top: 10px; }

.not_connect_call .two_buttons {
    display: block;
}
.not_connect_call .dismiss {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #2760A3;
    margin-top: 15px;
    cursor: pointer;
}
.not_connect_call p.not_connect {
    color: #2760A3;
}
@media (max-width: 1199px){ .connect-interpreter .Connecting_interpreter{ min-width: auto; max-width: 100%; } }
@media (max-width: 767px) {
	.processing-call-tab-content { padding: 15px 15px;}
	.processing-call-tab-content .connect-interpreter{ padding: 15px; }
	.connect-interpreter .Connecting_interpreter{ width: 100%; }
	.processing-call-tab-content .two_buttons{ flex-wrap: wrap; }
	.processing-call-tab-content .two_buttons button{ margin: 0 5px 10px 5px; }
	.processing-call-tab-content .two_buttons button.secondary{ padding: 6px 20px;     font-size: 16px;}
	.processing-call-tab-content .two_buttons button.primary{ padding: 6px 25px; }
	.processing-call-tab-content .inte-profile img{ width: 60px; }
	.processing-call-tab-content .inte-profile-details{  margin-left: 15px; width: calc((100% - 80px)); }
	.inte-profile-details span.connecting_person, .inte-profile-details span.connecting_person_names{     word-break: break-word; }
	.inte-profile-details>div{ flex-wrap: wrap; }
	.footer .copyright{ text-align: center; }
	.not_connect_call .connect-interpreter img.Connecting_interpreter { min-width: auto; max-width: 100%; width: unset; }
  }

 @media (max-width: 360px){ 
	button.primary{ font-size: 14px; }
	button.primary svg{ width: 22px; }
	.processing-call-tab-content .two_buttons button.primary{ padding: 6px 15px; }
	.call-person-form-main .cb-div{ font-size: 14px; }
}

@media (max-width: 350px){
	.sidebar-block{ padding: 22px 8px 20px; }
}



.connect-anim{
	width: 465px;
	margin: 0 auto;
	position: relative;
}
@media (max-width: 991px){
	.connect-anim{ width: 80%; }
}
.connect-anim > div{
	vertical-align: middle;
}
.connect-anim-1, .connect-anim-3{
	width: 20%;
	display: inline-block;
}
.connect-anim-2{
	width: 60%;
	display: inline-block;
}
.connect-anim-2 .dootedline{
	border-bottom: 1px dashed #2760A3;
	margin-top: 10px;
	width: 95%;
	margin: 0 auto
}
.connect-anim-2 marquee{
	width: 100%;
	height: 28px;
	margin-bottom: -17px;
}
.connect-anim-2 marquee i{
	font-size: 30px;
	color: #2760A3;
}
.floating-icon{
	position: absolute;
	top: 50px;
	right: 0;
	font-size: 16px;
	color: #2760A3;
}
.connect-anim img{
	width: 100%;
}
@media screen and (max-width: 568px){
	.floating-icon{
		font-size: 15px;
		right: -13px;
	}
}





/* modal css */
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  display: inline;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 0px 5px 0px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.popup-overlay{
	background: rgba(174, 174, 174, 0.5);
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
} 
.popup-content  {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
    border-radius: 8px;
}


.modal > .header {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2760A3;
}
.modal.interpreter> .header { border-bottom: none; }
.processingcall-modal-content{padding: 20px;    left: 147.5px;}
.modal.interpreter {   text-align: center;}
.processingcall-modal-content .content{ margin-top: 20px; }
@media (max-width: 991px) and (min-width: 768px){
	.processingcall-modal-content{    max-width: calc((100% - 140px));  width: auto;   left: 32.5px; }
}
@media (max-width: 767px){
	.processingcall-modal-content{    max-width: calc((100% - 140px));  width: auto;   left: 32.5px; }
	.processingcall-modal-content .modal > .actions{ padding: 10px 0px; }
}
@media (max-width: 350px){
	.processingcall-modal-content{    max-width: calc((100% - 140px));  width: auto;   left: 25.5px; }
	
}

/* modal css end*/

.not_connect_call .connect-interpreter svg path{  fill: #2760A3; }
.connect-interpreter .svg_girl .cross {
    position: absolute;
width: 42px;
height: 20px;
left: calc(50% - 42px/2);
/* top: 400px; */

}
.login-text{
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 18px;
text-align: center;
color: #818181;
}
.register-i-am-text{
    font-family: Mulish;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 18px;
color:#2760A3;
display:flex;
padding-top:3px;
justify-content:center
}

.register-text-2{
font-weight: 700;
font-family: Mulish;
font-style: normal;
font-size: 18px;
line-height: 26px;
margin-bottom: 15px;
text-align: center;
display: flex;
justify-content: center;
color: #818181;
    top: 50px;
    right: -40px;
}
.connect-interpreter .svg_girl {
    position: relative;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    margin-left: auto;
    margin-right: auto;
}
.not_connect_call .connect-interpreter .Connecting_interpreter {
    width: auto !important;
}

.selecteddropdown *{
	border:none !important;
}



/* login  css */
.login-text-top {
    text-align: center;
        margin-top:30px;
}
.phone-input-container{
    justify-content: center;
    display: flex;
    margin-top: 15px;
    /* margin-left: 5.5px; */
    width: 344px;
}
.login-text-top .login-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17.57px;
    text-align: center;
    color: #818181;
}

.register_links:last-child  a{ 
	font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #2760A3;
    text-decoration: none;
    justify-content: center;
    margin-top: 5px;
}
.register_links:last-child p {
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #2760A3;
}
.MuiGrid-root.header-login {
    width: 386px;
    /* height: 55px; */
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 40px;
    height: 240px;
}
/* .MuiGrid-root.header-login div{  } */
.MuiGrid-root.header-login div .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}
.MuiGrid-root.header-login form >.MuiGrid-root.MuiGrid-item >div>div {
    padding-left: 0px;
    padding: 2px 10px;
}
.MuiInputBase-input{
    font-size: 14px;
}
/* .MuiGrid-root.header-login button.MuiButtonBase-root.MuiButton-root {
    width: 100% !important;
} */
.MuiInputBase-input{
    font-size: 10px;
}
/* .MuiFormHelperText-root.Mui-error p#last_name-helper-text{
font-size: 30px;
} */
.MuiFormHelperText-root.Mui-error p #last_name{
    color: pink;
}
.MuiGrid-root.header-login input#mobile_number {
margin-bottom: 14px;
padding: 0;  
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 13px;
line-height: 18px;
}

.MuiGrid-root input#first_name {
margin-bottom: 19px;
padding: 0;
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 13px;
line-height: 18px;

}
.MuiGrid-root.placeholder-size input#dob {
background-color: #2196F3;
}
.MuiGrid-root input#email {
    padding-bottom: 2px;   
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 18px;
margin-bottom: 19px;
padding: 0;
}
.MuiGrid-root input#last_name {  
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 13px;
line-height: 18px;
margin-bottom: 19px;
padding: 0;
}
.MuiGrid-root.header-login form >.MuiGrid-root.MuiGrid-item>div {
    margin-bottom: 14px;
}
.MuiGrid-root.login-container {
    
    position: relative !important;
    min-height: 100vh;
    justify-content: flex-start;
    background-color: #eeeeee;
}

.btn_nt_blur{
    background-color: pink;
}

.btn_blur{
    background-color: blue;
}

/* OTP-Page design */
.MuiGrid-root.header-otp {
    width: 400px;
    height: 375px; 
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 10%;
    opacity: 0.94;
   
}
.container-otp{
    margin-top: 3.5%;
}
.enter-otp-text{
    color: #2760A3;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    font-family: mulish;
    margin-top: 4%;
}
.verify-button{
    margin-top: 8%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    color: #2760A3;
   
}
.resend-btn{
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 18px;
margin-top: 4%;
}
.otp-input{
display: flex;
justify-content: center;
}

 #email-helper-text {
    margin-top: -10px;
}
 #first_name-helper-text {
    margin-top: -10px;
}
 #last_name-helper-text {
    margin-top: -10px;
}
.register-email #mobile_number-helper-text {
    margin-top: -5px;
}
.register-email #dob-helper-text {
    margin-top: -11px;
}
/* responsive for register page */

@media (max-width: 360px){
	.register-main-container{    max-width: calc((100% - 140px));  width: 50px;   left: 25.5px; }
	
}

/* register-form page  */

/* @media (max-width: 360px){
	.form-container{      width: 320px;    }
	.Transparent {width:0}
} */
.special-svg svg > path:nth-child(2){
	-webkit-transform-origin: 20% 98%;
	        transform-origin: 20% 98%;
	transform-box: fill-box;
	-webkit-animation: spin 5s infinite;
	        animation: spin 5s infinite;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
}
@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	25% { -webkit-transform: rotate(90deg); transform: rotate(90deg); }
    50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
    75% { -webkit-transform: rotate(270deg); transform: rotate(270deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes spin {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	25% { -webkit-transform: rotate(90deg); transform: rotate(90deg); }
    50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
    75% { -webkit-transform: rotate(270deg); transform: rotate(270deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
/*.extra-shadow-bottom{
	box-shadow: 0px -14px 35px 12px #0000000d;
}*/
input#state_input {
    font-size: 13px;
    font-weight: 600;
    font-family: mulish;
    font-style: normal;
    line-height: 18px;
}

input#city_input {
    font-size: 13px;
    font-weight: 600;
    font-family: mulish;
    color: #616161;
    font-style: normal;
    line-height: 18px;
}
input#search_input {
    font-size: 13px;
    font-weight: 600;
    font-family: mulish;
    color: #616161;
    font-style: normal;
    line-height: 18px;
}
span.chip.singleChip.false {
    font-size: 13px;
    font-family: 'Mulish';
    font-weight: 700;
    color:black;
    
}
div#state {
    margin-left: -3px;
}
div#city {
    margin-left: -9px;
}
div#multiselectContainerReact {
    margin-left: -9px;
}

.connect-interpreter .svg_girl .cross path{ fill: #F54A4A; }

	.connect-interpreter .svg_girl .cross{    left: unset;    right: 0;
    top: 55px;} 



/* new css*/
@media (max-width: 767px){
	.makeStyles-mainDiv-6{
		height: 541px !important;
		flex-direction: column;
	}
	
	.makeStyles-main-25{
		display: block !important;
	}
	.makeStyles-Transparent-24{
		width: 100% !important;
	}
}
 
/* calender icon style */
span.MuiIconButton-label {
    margin-top: 1px;
}
input.MuiInputBase-inputAdornedStart {
    padding: 0;
    margin-top: 1px;
	
	
}
.makeStyles-row-34{
    width: 97% !important;
    padding-left: 6px !important;
}
.makeStyles-row-19{
    width: 97% !important;
    padding-left: 6px !important;
}
@media (min-width: 320px) and (max-width: 320px){
.register-main-container {
    max-width: 100%;
    width: 100%;
    left: unset;
}
.makeStyles-mainCenter-12{
	display: block !important;
}
.makeStyles-inputContainer-2{
	margin:5px !important;
}
.makeStyles-div1-7{
	width: 100% !important;
}
.makeStyles-mainDiv-6{
	width: 100% !important;
	display:block !important;
	padding-top: 25px !important;
	height: 480px !important;
}
.makeStyles-div2-8{
	width: 100% !important;
}
.makeStyles-row-3{
	width: 100% !important;
}
}
span.Responseerr {
    color: red;
    font-size: 14px;
    font-family: 'Mulish';
    display: flex;
    justify-content: center;
}

.profile_intial{
  width: 200px;
  height: 200px;
  border-radius: 50%;
 background: #699bf7;
  font-size: 40px;
  color: #fff;
  text-align: center;
  line-height: 200px;
  margin: 0;
}
.single_profinitial{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #699bf7;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 65px;
  margin: 0;
}

@media (max-width: 991px){
	.single_profinitial{     width: 40px;    line-height: 40px; font-size: 16px;  height: 40px; }
}

@media (min-width: 993px){
	.MuiGrid-root.contact-info>div{ height: 100%;  }
}

.loading_spinner {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}
.nodata_found {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    color: #515151;
    font-family: 'Mulish';
}
.language-content button.close{      top: 2px;    right: 2px;   border: none;    box-shadow: none; }
.language-content button.close svg {
    width: 15px;
}
.popup-content.language-content{ width: 300px; }
.popup-content.language-content .form-div {
    background: transparent;
    border: 0.5px solid #92B8E5;
    border-radius: 8px;
    text-align: left;
    padding: 10px;
}
.popup-content.language-content .form-div  label{ 
	display: block;     
	font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #5490D6; 
}
.popup-content.blockReason-content{ width: 330px; }
.popup-content.blockReason-content .form-div {
    background: transparent;
    border: 0.5px solid #92B8E5;
    border-radius: 8px;
    text-align: left;
    padding: 10px;
}
.popup-content.blockReason-content .form-div  label{ 
	display: block;     
	font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #5490D6; 
}
select#language,select#state,select#city{ 
	width: 100%; 
	border: none;    
	margin-top: 5px; 
	font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #616161;
}
svg.Icon_Contact_video{ width: 35px;  }
svg.Icon_Contact_video:not(.active) path{ fill: #9A9A9A; }
svg.Icon_Contact_video:not(.active) rect{ stroke : #9A9A9A;}
.header-main .user-profile .profile_intial {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #699bf7;
    font-size: 20px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    margin: 0;
    vertical-align: top;
    margin-top: 0;
    float: left;
}



@media (min-width: 1024px) and (max-width: 1199px){
	.header-user-profile {
	    width: 30%;
	    position: relative;
	}
	.header-main-nav-inner {
	    padding: 0 25px;
	    width: 70%;
	}
	
}


@media (max-width: 1199px) and (min-width: 1080px){
    .home-user-profile-img .profile_intial { width: 180px; height: 180px; line-height: 180px; }
}

@media (max-width: 1079px) and (min-width: 1024px){
    .home-user-profile-img .profile_intial { width: 150px; height: 150px; line-height: 150px; }
}


@media (min-width: 992px) and (max-width: 1023px){
	.header-user-profile {
	    width: 30%;
	    position: relative;
	}
	.header-main-nav-inner {
	    padding: 0 25px;
	    width: 70%;
	}
	.header-main-nav-inner > div{     padding: 0 15px; }
	.home-user-profile-img .profile_intial { width: 150px; height: 150px; line-height: 150px; }
}

@media (min-width: 993px){
	.header-main .user-profile-details {
	    max-width: calc((100% - 65px));
	}
	.header-main .user-profile-details .user-name{     max-width: calc((100% - 29px)); }
	.main-grid.home-grid >.MuiGrid-root>.box-shadow {
	    height: 100%;
	}


}



 
.custom_scrollbar .MuiTabs-flexContainer{
	overflow-x: scroll;
	scrollbar-color: #699bf7 #fff;
	scrollbar-width: thin
}

.custom_scrollbar .MuiTabs-flexContainer::-webkit-scrollbar {
	width: 2px;
	height: 5px;
}

.custom_scrollbar .MuiTabs-flexContainer::-webkit-scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::-webkit-scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::-moz-scrollbar {
	width: 2px;
	height: 5px;
}

.custom_scrollbar .MuiTabs-flexContainer::-moz-scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::-moz-scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::-ms-scrollbar {
	width: 2px;
	height: 5px;
}

.custom_scrollbar .MuiTabs-flexContainer::-ms-scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::-ms-scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::-o-scrollbar {
	width: 2px;
	height: 5px;
}

.custom_scrollbar .MuiTabs-flexContainer::-o-scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::-o-scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::scrollbar {
	width: 2px;
	height: 5px;
}

.custom_scrollbar .MuiTabs-flexContainer::scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.custom_scrollbar .MuiTabs-flexContainer::scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}
.call-history-detail.vertical_scrollbar,.callperson.vertical_scrollbar,.mycontacts-detail.vertical_scrollbar,.con_callperson.vertical_scrollbar{ max-height: calc((100vh - 300px)) }


.vertical_scrollbar{
	overflow-y: scroll;
	scrollbar-color: #699bf7 #fff;
	scrollbar-width: thin
}

.vertical_scrollbar::-webkit-scrollbar {
	width: 5px;
}

.vertical_scrollbar::-webkit-scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.vertical_scrollbar::-webkit-scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}

.vertical_scrollbar::-moz-scrollbar {
	width: 5px;
}

.vertical_scrollbar::-moz-scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.vertical_scrollbar::-moz-scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}

.vertical_scrollbar::-ms-scrollbar {
	width: 5px;
}

.vertical_scrollbar::-ms-scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.vertical_scrollbar::-ms-scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}

.vertical_scrollbar::-o-scrollbar {
	width: 5px;
}

.vertical_scrollbar::-o-scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.vertical_scrollbar::-o-scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}

.vertical_scrollbar::scrollbar {
	width: 5px;
	
}

.vertical_scrollbar::scrollbar-track {
	background: 0 0;
	border-radius: 10px
}

.vertical_scrollbar::scrollbar-thumb {
	background: #699bf7;
	border-radius: 10px
}



.otptimer>div>button{
	color: #3f51b5 !important;
	padding: 6px 2px;
	font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
        vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent !important;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 12px;
    
}
.MuiGrid-root.otptimer {
    justify-content: center;
    display: flex;
}
.smalltitle {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #5490D6;
    margin-bottom: 10px;
}
.history-persons {
    display: flex;
    align-items: center;
}
.history-persons .call-person-img {
    width: 50px;
}
.history-persons .call-person-img img {
    width: 100%;
}
.history-person-name {
    width: calc((100% - 120px));
    text-align: left;
    padding-left: 15px;
}
.call_details_inner {
    border-bottom: 0.75px solid #D7D7D7;
    padding: 10px 0px;
}
.call_details_inner .content {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #515151;
}
.call_details_inner .time {
    display: inline-block;
    width: 75%;
}
.call_details_inner .duration {
    display: inline-block;
    width: 25%;
    float: right;
    text-align: right;
}
.call_details_inner .duration div {
    text-align: right;
    float: right;
}
.historygrid .box-shadow > .call-person-tab-header h3 {
    text-align: center;
    padding-top: 10px;
}
.historygrid .call-person-tab-content {
    box-shadow: none;
}
.videocallbtn img {
    width: 30px;
}
.MuiGrid-root.main-grid.callhistory .single_callhistory .call_names_inter {
    width: 100%;
    margin-left: 0px;
}
.MuiGrid-root.main-grid.callhistory .single_callhistory .call_names_inter{ text-align: left; }
.MuiGrid-root.main-grid.callhistory .single_callhistory .imgaes {
    text-align: left;
    float: left;
}
.MuiGrid-root.main-grid.home-grid .call-person-search-form {  margin-bottom: 20px;}
.MuiTabs-root.hidetab {
    display: none;
}
.call-person-search-form.callperson{ margin-bottom: 15px; }
.call-person-tab-content .callperson .call-person-details .call-person-img ,.call-person-tab-content .con_callperson .call-person-details .call-person-img {
    width: 80px;
}
.call-person-tab-content .callperson .call-person-details .call-person-video-call ,.call-person-tab-content .con_callperson .call-person-details .call-person-video-call{
    width: 50px;
}
.call-person-tab-content .callperson .call-person-details .call-person-inner-details ,.call-person-tab-content .con_callperson .call-person-details .call-person-inner-details{
    width: calc((100% - 135px ));
}
.view-balance.MuiGrid-item > .box-shadow{ padding: 15px; }
.page-main-inner.viewaccount_balance .walletbalance{
	background: #5490D6;
    border-radius: 8px;
}
.MuiGrid-root.view-balance .walletbalance{
    color: #fff;
}
.page-main-inner.viewaccount_balance .walletbalance img{ width: 80px; }
.page-main-inner.viewaccount_balance .walletbalance .MuiGrid-root{ align-items: center; margin: 0;     width: 100%;}
.page-main-inner.viewaccount_balance .walletbalance >.MuiGrid-root>.MuiGrid-root:first-child{ text-align: left; font-weight: 700;}
.page-main-inner.viewaccount_balance .walletbalance >.MuiGrid-root>.MuiGrid-root:last-child{ text-align: right; }
.page-main-inner.viewaccount_balance .call-person-tab-header{ margin-top: 30px; }
.page-main-inner.viewaccount_balance .plans-amt {
    background: #D0E1F4;
    border-radius: 8px 8px 0px 0px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 6px 20px;
}
.page-main-inner.viewaccount_balance .plans.renew .plans-amt{ background: #2760A3; }
.page-main-inner.viewaccount_balance .plans {
    border: 0.5px solid #92B8E5;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: left;
    margin-bottom: 15px;
}
.page-main-inner.viewaccount_balance .amount {
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
    text-align: right;
    color: #FFFFFF;
}
.page-main-inner.viewaccount_balance .plansdetail button{ width: 20% ;    padding: 6px;     line-height: 24px;}
.page-main-inner.viewaccount_balance .plans:not(.renew) .plansdetail button{ 
	border: 0.75px solid #2760A3;
    background: transparent;
    color: #2760A3; 
}
.page-main-inner.viewaccount_balance .plansdetail .plansdetail_inner{ width: 80% }
.page-main-inner.viewaccount_balance .plansdetail {
    display: flex;
    align-items: center;
    padding: 0px 20px;
}
.page-main-inner.viewaccount_balance .plansdetail ul {
    padding-left: 15px;
}
.page-main-inner.viewaccount_balance .plansdetail ul  li::marker {
  
  color: #FF9D00;
}
.page-main-inner.viewaccount_balance .plansdetail ul  li {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #474747;
}
.page-main-inner.viewaccount_balance .tryfreetrial {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #474747;
    justify-content: center;
}
.page-main-inner.viewaccount_balance .allchange_plan {
    display: flex;
    margin: 0px -10px;
    margin-top: 10px;
}
.page-main-inner.viewaccount_balance .allchange_plan button {
    margin: 0 10px;
    border: 0.75px solid #2760A3;
    background: transparent;
    color: #2760A3; 
}
.callhistory .call-history-inner .call-history-detail {
    margin-top: 20px;
}
.call-person-form-main .profile_form .form-div{ text-align: left; }
.page-main-inner.profile .edit-profile .call-person-form-main {
    padding-top: 0px;
}
.page-main-inner.profile .edit-profile .call-person-tab-header {
    padding: 20px;
}
.page-main-inner.profile .MuiGrid-root input#email{ margin-bottom: 0px; }
form.profile_form div#state .singleSelect,form.profile_form div#city .singleSelect,form.profile_form div#language .singleSelect{ 
	border: none;
    padding: 0 5px;
    min-height: unset;
 }
form.profile_form div#state .singleSelect span.chip.singleChip.false,form.profile_form div#city .singleSelect span.chip.singleChip.false,form.profile_form div#language .singleSelect span.chip.singleChip.false{ padding: 0; margin: 0px;}
form.profile_form div#city {
    margin-left: -5px;
}
.single_callhistory.active_callhistory {
    background: #D0E1F4;
}
.history-persons .single_profinitial{ 
	width: 50px;
    height: 50px; 
    line-height: 50px;
    font-size: 16px;
    margin-bottom: 10px;
}
.Icon_member_added svg.Icon_Contact_video path {
    fill: #2760A3;
}
@media (min-width: 993px){
	.historygrid .call-person-profile .call-person-tab-header h3{ font-size: 20px; }
}
@media(min-width: 501px){
.page-main-inner.profile form.profile_form >button {
    width: 30%;
}	
}

@media (max-width: 767px){
	.call-history-detail.vertical_scrollbar, .callperson.vertical_scrollbar, .mycontacts-detail.vertical_scrollbar ,.con_callperson.vertical_scrollbar{
	    max-height: 250px;
	}
	.page-main-inner.viewaccount_balance .walletbalance .MuiGrid-root{ flex-wrap: nowrap; }
}


@media (max-width: 499px){
	.page-main-inner.viewaccount_balance .allchange_plan{ flex-wrap: wrap; }
	.page-main-inner.viewaccount_balance .allchange_plan button:first-child{ margin-bottom: 10px; }
	.page-main-inner.viewaccount_balance .plansdetail{ flex-wrap: wrap; }
	.page-main-inner.viewaccount_balance .plansdetail button{ width: fit-content; width: -moz-fit-content; width: -webkit-fit-content; }
	.page-main-inner.viewaccount_balance .plansdetail .plansdetail_inner{ width: 100%; }
	.page-main-inner.viewaccount_balance .plansdetail button{ margin-bottom: 15px; }
	.page-main-inner.viewaccount_balance .plansdetail button{ font-size: 14px;     padding: 3px 10px;}
}
input#password {
  
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 19px;
}
span.onbreak-text {
    color: red;
    font-size: 14px;
    font-family: 'Mulish';
    display: flex;
    /* justify-content: center; */
    margin-bottom: 12px;
    text-align: center;
}
.forgot-password-grid{
  
        margin-top: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
  
}

.full-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    display: grid;
    flex: 1 1;
    flex-direction: column;
    background-color: rgb(33, 32, 50);
}

/* Incoming page css */
.IncomingCallLogo{
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 2%;

}
.calltext{
text-align: center;
color: white;
margin-top: 1%;
}
.incoming-call-button{
    text-align: center;
color: white;
margin-top: 12%;
}
img.incoming-call-profile-img {
    width: 155px;
    height: 100px;
    border-radius: 75px;
    margin-top: 20px;
}
.decline-button{
text-align: center;   
color: white;

}
span.MuiButton-label.decline {
    color: white;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.decline {
    color: white !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.video-button {
    border-radius: 8px;
    color: #1D487A;
    font-weight: 600;
    font-size: 14px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.decline-button {
    color: white;
    font-weight: 600;
    font-size: 14px;
}
.video-call-person-name{
    font-size: 18px;
}
.interpreter-feedback-inner-dropdown {
    height: 47px;
    padding: 2%;
    border-radius: 8px;
    border: 0.02px solid #D0E1F4;
}
.participants-contact{
    /* box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 120px;
    height: 20px;
    border: 20px solid #faf6f6; */
    border-style: solid;
    border-width: 1.5px;
    border-color: #FFFFFF;
    padding: 6px;
    border-radius: 8px;
}
.contact-detail-popup-content{
    position: relative;
        /* margin: auto !important; */
       pointer-events: auto;
       width: 20%;
       margin-left: auto !important;
       margin-bottom: 20px !important;
       margin-right: 165px !important;
       background-color: #1D487A;
}
.participants-name{
    color: white;
    margin-left: -40px;
    align-content: center;
}
.participants-mobile{
    display: flex;
    color: white;
    margin-left: 40px;
}
span.participants-mobile {
    margin-left: 30px;
}
.participants-contact span {
    /* margin-left: 10px; */
}
.signable-text-header{
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-left: -5%;
    margin-top: 3%;
}
.back-img-calldetails{
    float: right;
}
.callhistory-initiator-name{
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 18px;
    color: black;
    opacity: 0.5;
}
.call-date{
	font-weight: bold;
    color: #515151;
    opacity: 0.5;
}
.callhistory-participants-time{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: grey;
    width: 90px;
}
.MuiGrid-root.otptimer .MuiButton-root.Mui-disabled {
    color: #5490D6 !important;
    font-family: mulish !important;
    font-size: 14px !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.Mui-disabled.MuiButton-fullWidth.Mui-disabled {
    color: #5490D6 !important;
    font-family: mulish !important;
    font-size: 14px !important;
}

.otptimer{
text-align: center;
}
span.error-msg {
    color: red;
    text-align: center;
    display: grid;
    font-size: 14.3px;
    font-family: mulish;
    margin-top: 2%;
}
.audiovideo-main{
    width: 100%;
    /* height: 550px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* background-color: white; */
    /* background-color: white; */
    /* border: 2px solid rgb(211, 126, 126); */
    border-radius: 10px;
    /* border:2px solid #2196F3; */

}
.audiovideo-grid1{
    /* border:2px solid #0c0c0c; */
    /* height: 200px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    
}
.audiovideo-grid11{
    /* border:2px solid #b70606; */
    /* height: 100px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.sound-wave{
    width: 80%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .micdiv{
    width: 80%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 15px;
    margin-bottom: 15px;
    /* border: 1px solid grey; */
  }

  .cameratest-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border:2px solid #22538a; */
    justify-content: stretch;
    margin-top: 15px;
  }
  .cameratest-inner1{
    /* border:2px solid #22538a; */
    /* margin-right: 150px; */
    /* margin-top: 20px; */
    margin-bottom: 20px;
  }
  .micbutton1{
    justify-content: flex-start;
    align-items:center;
    margin-right: 2% !important;
    margin-left: 1% !important;
    /* width: 100px; */
    /* padding: 50px; */
    /* border:2px solid #2196F3; */
  }
  .video_micbutton1{
    justify-content: flex-start;
    align-items:center;
    margin-right: 2% !important;
    width:150px;
    /* width: 100px; */
    /* padding: 50px; */
    /* border:2px solid #2196F3; */
  }
  .video_stop_micbutton1{
    justify-content: flex-start;
    align-items:center;
    margin-right: 2% !important;
    width:150px;
    margin-top: 5% !important;
    /* width: 100px; */
    /* padding: 50px; */
    /* border:2px solid #2196F3; */
  }
  .cameratest-inner2{
    /* border: 2px solid black; */
  }
  .webcamcss{
    /* border: 2px solid black; */
    border-radius: 4px;
  }
  .micclass{
    padding: 10px;
    /* color: #2760A3; */
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
  }
  .speakerclass{
    padding: 10px;
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
  }
  .micdivinner2{
    display: none;
  }
  .speakermain{
    margin-top: 25px;
  }
  .video-text-css{
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
  }
  .reason {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    min-height: 22px;
    position: relative;
    background-color: #f9f8f9;
    width: 92%;
}
.colored-circle {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: black;
    height: 20px;
    width: 20px;
  }
   .red {
  position: absolute;
  background: #FF4B26;
  width: 19px;
  height: 19px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50px;
  /* display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: red;
    height: 20px;
    width: 20px; */
}
.green {
    position: absolute;
    background: green;
    width: 19px;
    height: 19px;
    border-radius: 2px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    /* color: #FFFFFF; */
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50px;
    
  }
  .category-link.active {
	color: red;
	position: relative;
	text-transform: capitalize;
}

.category-link.active::before {
	content: '';
	height: .65em;
	width: .65em;
	background: currentColor;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: -.65em;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}


.fa {
    width: 4px !important;
    height: 4px !important;
    font-size: small !important;
    color: red;
    /* font-weight: normal !important; */
    /* font-family: 'Courier New' !important; */
  }
  .micbutton3{
    margin-right: 2% !important;
    margin-left: 1% !important;
  }
  
  .buttonrad{
    border-radius: 8px !important;
  }
  .textColor{
    color: #474747;
}
.user_interpreter_add_div{
    border-radius: 20px !important;
}


.span-call{
    font-size: 12px;
    text-decoration: unset;
    margin-left: 5px;

}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSizeSmall.MuiButton-sizeSmall {
   
    /* padding: 6px; */
    /* margin-left: 10px; */
    margin-right: 8px;
}
.headercall-mygroups .group-member-inner-details{
    border-radius: 9px;
    /* box-shadow: 0px 4px 35px 12px #0000000d; */
    align-items: center;
    padding: 5px 0;
    justify-content: center;
    display: flex;
}
.headercall-mygroups .count{
    /* margin-left: 30px; */
}
.call-button{
    padding-left: 26px;

}
.group-member-img {
    /* width: 25%; */
    text-align: center;
    justify-content: center;
    display: flex;
}
.member{
    display: grid;
    align-items: center;
    text-align:center; 
    justify-content: center;
}
.two-buttons{
    margin-top: 10px;
}
.member-form{
   
        border-radius: 8px;
        margin-top: 10px;
    
}
.group-created{
    display: grid;
    /* float: left; */
    margin-right: 220px;
    margin-top: 18px;

}
.cell {
    margin-top: 15px;
    border-bottom: 0.75px solid #D7D7D7;
  }
  .group-icon-img{
    width:"25%"
  }
  .call-group-info{
      display: flex;
  }
  .delete-btn{
      color: red;
  }
  .MuiButton-text {
    padding: 6px 8px;
    /* color: aqua; */
    color: red;
   text-transform: lowercase;
}
.sections-created{
    margin-top: 20px;
}

.created-grid {
    text-align: left;
    padding-left: 15px;
}
.group-language{
    text-align: right;
    padding-right: 15px;
}
.call-btn{
    margin-right: 16px;
}
.member{
    text-align: left;
    padding-left: 15px;
    font-size: 14px;
}
.viewall {
    font-size: 14px;
    margin-left: 258px;
}
.date{
    font-size: 14px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.delete-btn {
    color: red;
}

.member-delete{
    width: 30px;
    margin-left: 200px;
    margin-top: 20px;
}
    
img.Icon_member {
    width: 30px;
   
}
.Icon_member_added{
    color:blue
}
.group-call-bottom-content{
	margin-bottom: 15px;
    margin-top: 10px;
}
span.MuiButton-label {
    padding: 1px;
}
.Icon_member-btn{
    width: 20px;
    margin-right: 7px;
}
.vertical_scrollbar{
	overflow-y: scroll;
	scrollbar-color: #699bf7 #fff;
	scrollbar-width: thin
}

.box-shadow.text-center.member-form.vertical_scrollbar {
    max-height: calc((50vh - 2px));
}
.box-shadow.text-center.popup.vertical_scrollbar {
    max-height: calc((80vh - 2px));
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.back-button {
    margin-right: 85%;
}

.box-shadow.text-center.group-list.vertical_scrollbar {
    max-height: calc((50vh - 2px));
}
.MuiGrid-root.login-container1 {
    
    position: relative !important;
    min-height: 50vh;
    justify-content: flex-start;
    background-color: white;
}
button.close1 {
    margin-left: 86%;
    margin-top: 1.5%;
}
/* interpreterr css starts here */
.Interpreter-login-header{
    /* display: grid; */
    justify-content: center;
     display: flex;
}
.edit {
    /* float: left; */
    float: right;
    margin-left: 12px;
    padding-left: -12px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    padding-right: 10px;
}
img.edit-img {
    float: right;
    width: 30px;
}

.MuiGrid-root.login-container.interpretlogin.MuiGrid-container.MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center .MuiGrid-root.header-login {
    margin: 0px;
}

.MuiGrid-root.login-container.interpretlogin.MuiGrid-container.MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center {
    background: white;
}
.login-text-interpreter{
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 17.57px;
    text-align: center;
    color: #818181;

}
.group-person-missed-call{
width: 60px;
height: 60px;
border-radius: 30px;
/* border:2px solid black */
}
.missedcall-names {
    font-weight: bold;
    color: #515151;
}
.interpreter-profile-edit-form-main{
    padding-left: 18px;
}
.call-person-form-main-interpreter input[type=checkbox]{
	position: relative;
    z-index: 9;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    opacity: 0;
}

.call-person-form-main-interpreter .form-div-interpreter {
    border: 0.75px solid #5490D6;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 2px 10px 5px;
}
.interpreter-profile-form-main{
	padding: 20px 20px 50px;
}
.Interpreter-details{
font-weight: 600;
font-size: 18px;
color:#717171;
}
.single_profinitial-interpreter {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #B4C6E2;
    font-size: 20px;
    color: #597FBB;
    text-align: center;
    line-height: 65px;
    margin: 0;
    margin-left: 12px;
}
.call-person-status-interpreter {
    font-weight: 700;
    font-size: 15px;
   
    color: #515151;
}
.missedcall-names-1 {
    font-weight: bold;
    color: #515151;
    margin-left: 30px;
}
.interpreter-time {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #ABABAB;
}
.interpreter-time-1{
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #ABABAB;
    text-align: left;
}
.interpreter-time-1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #ABABAB;
    text-align: right;
    /* margin-left: -12px; */
    margin-right: 19px;
}
h3.text {
    text-align: center;
    color: #515151;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
}
.edit-title-profile{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgba(81, 81, 81, 0.6);
}
.box-shadow.radius-8px.vertical_scrollbar {
    max-height: calc((61vh - 2px));
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.break-reason {
    padding: 5px 15px;
    border: 1px solid #2760A3; 
    border-radius: 22px;

}
.interpreter-feedback-inner {
    border-radius: 8px;
    border: 0.005px solid #D0E1F4;
}
.feedbackinfo-inner {
    padding: 10px 30px 30px;
}
.feedback-inner-title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 630;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
}
.MuiFormControl-root.MuiTextField-root.textbox-style {
    border: 1px solid #D0E1F4;
    border-radius: 8px;
}
.interpreter-feedback-inner {
    height: 100px;
}
.feeback-title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 640;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #818181;
}
.MuiGrid-root.feeback-btn.MuiGrid-grid-md-4 {
    justify-items: center;
    display: block;
    align-items: center;
    margin: 0px auto;
}
.btn_nt_blur{
    width:"302px";
    border-radius:"8px";
    background-color: "#2760A3"
  }
  .btn_blur{
   
   border-radius:"8px";
   background-color: #D0E1F4 !important;
   
    /* background: #2760A3; */
   
  }
.form-div{
    border: 0.75px solid #2760a3;
    border-radius: 8px;
    margin: 12px;
    padding: 2px 10px 5px;
}
.call-person-form-main .form-div input:not(input[type=checkbox]){
    border: none;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
}
.form-input{
    border: none;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
}
option:first-letter {
  background-color: yellow;
  color: red;
}
select#language_id {
    color: black;
    font-size: small;
}

.MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup {
    background-color:white !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.7); */
    border-bottom: none !important;
}
.MuiInput-underline:hover(.Mui-disabled):before {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.7); */
    border-bottom: none !important;
}


